<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{ $tc("screens_limit.title") }}</span>

        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col cols="12" sm="3" md="3">
              <div class="main">
                <img :src="image" alt="" srcset="" />
                <span class="exclamation-mark">i</span>
              </div>
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <p class="message">{{ $t("screens_limit.message") }}</p>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn color="buttons" :min-height="50" block>
                {{ $t("screens_limit.upgrade") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "screens-limit",
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      image: require("@/assets/monitor.png"),
    };
  },
};
</script>

<style scoped>
.main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main img {
  width: 100px;
  height: 100px;
  filter: grayscale(100%);
}

.message {
  font-size: 18px;
  margin-top: 20px;
  text-align: justify;
  color: rgb(107, 107, 107);
}

.exclamation-mark {
  color: rgb(155, 0, 0);
  font-weight: bold;
  position: absolute;
  font-size: 30px;
}
</style>