<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $tc("screens.title", 2) }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn
            color="buttons"
            v-if="userDoc.role == 'sa'"
            class="mr-5"
            @click="reportDialog = true"
            :loading="loading"
          >
            <v-icon left> fas fa-chart-pie </v-icon>
            {{ $tc("screens.report") }}
          </v-btn>

          <v-btn
            color="buttons"
            @click="showAddScreenDialog"
            :loading="loading"
          >
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("screens.add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search :loading="loading" :name="$tc('screens.title', 0)" />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="screens"
            :loading="loading"
            :showInfo="true"
            :showEdit="true"
            :showDelete="true"
            :headers="headers"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="deviceStatusDialog"
      persistent
      v-model="deviceStatusDialog"
      max-width="850px"
      scrollable
    >
      <device-status
        :screen="selectedScreen"
        @success="screenAdded"
        @cancel="deviceStatusDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="addScreenDialog"
      persistent
      v-model="addScreenDialog"
      max-width="600px"
      scrollable
    >
      <add-screen
        :originalScreen="selectedScreen"
        @success="screenAdded"
        @cancel="addScreenDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="wifiDialog"
      persistent
      v-model="wifiDialog"
      max-width="900px"
    >
      <wifi-file @cancel="wifiDialog = false" />
    </v-dialog>

    <v-dialog
      v-if="reportDialog"
      persistent
      v-model="reportDialog"
      max-width="1000px"
      scrollable
    >
      <screens-report @cancel="reportDialog = false" />
    </v-dialog>

    <v-dialog
      max-width="500px"
      v-if="deleteScreenDialog"
      v-model="deleteScreenDialog"
    >
      <delete-screen
        ref="deleteComponent"
        :name="selectedScreen.name"
        @success="confirmDelete"
        @cancel="deleteScreenDialog = false"
      />
    </v-dialog>

    <v-dialog
      max-width="700px"
      v-if="screenLimitDialog"
      persistent
      v-model="screenLimitDialog"
    >
      <screens-limit @cancel="screenLimitDialog = false" />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import addScreen from "./add-screen";
import deleteScreen from "@/components/delete";
import deviceStatus from "@/components/device-status";
import filesTable from "@/components/files-table";
import wifiFile from "@/components/wifi-file";
import screensLimit from "@/components/screens-limit";
import screensReport from "@/components/screens-report.vue";

export default {
  name: "Screens",
  components: {
    addScreen,
    deleteScreen,
    deviceStatus,
    filesTable,
    wifiFile,
    screensLimit,
    screensReport,
  },
  data() {
    return {
      screens: [],
      snackbarText: "",
      snackbar: false,
      randomKey: 0,
      loading: true,
      addScreenDialog: false,
      selectedScreen: null,
      configureScreenDialog: false,
      wifiDialog: false,
      deleteScreenDialog: false,
      countries: [],
      agencies: [],
      cities: [],
      selectedCountry: "",
      selectedCity: "",
      selectedAgency: "",
      screenLimitDialog: false,
      deviceStatusDialog: false,
      reportDialog: false,
      screensBalena: [],
      headers: [
        {
          text: this.$tc("headers.device_name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$tc("headers.description"),
          value: "description",
          width: "150px",
        },
        {
          text: this.$tc("headers.assigned_agency"),
          value: "assignedAgency",
          width: "250px",
        },

        {
          text: this.$tc("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "180px",
        },
      ],
    };
  },

  computed: {
    ...mapState(["groupId", "group", "userDoc"]),
  },
  methods: {
    ...mapActions(["cleanSearch"]),
    showAddScreenDialog() {
      if (this.group.screensAllowed > this.screens.length) {
        this.selectedScreen = null;
        this.addScreenDialog = true;
      } else {
        this.screenLimitDialog = true;
      }
    },
    editScreen(item) {
      this.selectedScreen = item;
      this.addScreenDialog = true;
    },
    showDeviceStatus(item) {
      this.selectedScreen = item;
      this.deviceStatusDialog = true;
    },
    showDeleteDialog(item) {
      this.selectedScreen = item;
      this.deleteScreenDialog = true;
    },
    handleMenuActions(payload) {
      switch (payload.action) {
        case "screen_status":
          this.showDeviceStatus(payload.item);
          break;
        case "edit":
          this.editScreen(payload.item);
          break;
        case "delete":
          this.showDeleteDialog(payload.item);
          break;
      }
    },

    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection("screens")
        .doc(this.selectedScreen[".key"])
        .delete()
        .then((response) => {
          this.snackbarText = this.$tc("screens.deleted");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteScreenDialog = false;
        })
        .catch((error) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },

    screenAdded(payload) {
      this.addScreenDialog = false;
      this.snackbarText = payload;
      this.snackbar = true;
    },

    getBalenaScreens() {
      return new Promise((resolve, reject) => {
        httpDevicesReport()
          .then((result) => {
            this.screensBalena = result.data;
            resolve("success");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  async mounted() {
    this.cleanSearch();

    // await this.getBalenaScreens();

    await this.$binding(
      "screens",
      db.collection("screens").where("groupId", "==", this.groupId)
    ).then((res) => {});

    await this.$binding(
      "agencies",
      db.collection("agencies").where("groupId", "==", this.groupId)
    );

    this.screens.forEach((item) => {
      let agencyResult = this.agencies.find(
        (agency) => agency[".key"] == item.agency
      );
      item.assignedAgency = agencyResult ? agencyResult.name : "";

      // let searchBalena = this.screensBalena.find()
    });
    this.loading = false;
  },
};
</script>