<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{ $t("wifi_file.name") }}</span>
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col class="mt-3" cols="12" sm="6" md="6">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="ssid"
                    :label="$tc('dialog.ssid')"
                    rounded
                    autocomplete="off"
                    filled
                    clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="password"
                    :label="$tc('dialog.password')"
                    rounded
                    autocomplete="off"
                    filled
                    :append-icon="show1 ? 'fa-eye' : 'fa-eye-slash'"
                    :type="show1 ? 'text' : 'password'"
                    clearable
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    min-height="50"
                    block
                    @click="generate"
                    class="ma-0"
                    color="buttons"
                    >{{ $tc("button.download") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <small>{{ $t("wifi_file.instructions") }}</small>
              <hr class="mb-8" />
              <p style="text-align: justify">
                1. {{ $tc("wifi_file.step_1") }}
              </p>
              <p style="text-align: justify">
                2. {{ $tc("wifi_file.step_2") }}
              </p>
              <p style="text-align: justify">
                3. {{ $tc("wifi_file.step_3") }}
              </p>
              <p style="text-align: justify">
                4. {{ $tc("wifi_file.step_4") }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FileSaver from "file-saver";

export default {
  name: "wifi-file",
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      ssid: null,
      password: "",
      show1: false,
    };
  },

  methods: {
    generate() {
      if (this.ssid) {
        var blob = new Blob(
          [
            `[connection]
id=resin-wifi-01
type=wifi

[wifi]
hidden=true
mode=infrastructure
ssid=${this.ssid}

[ipv4]
method=auto

[ipv6]
addr-gen-mode=stable-privacy
method=auto

[wifi-security]
auth-alg=open
key-mgmt=wpa-psk
psk=${this.password}`,
          ],

          { type: "text/plain", autoBom: false }
        );
        saveAs(blob, "wifi-connection");
      } else {
        this.snackbarText = this.$t("wifi_file.insert_ssid");
        this.snackbar = true;
      }
    },
  },
};
</script>