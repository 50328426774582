<template>
  <v-card class="d-flex flex-column">
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card-title class="grid-close">
      <span class="headline">{{ $t("reports.name") }}</span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid class="pa-5">
        <v-row>
          <v-col c v-if="!loading" class="tags" cols="12" sm="12" md="12">
            <div
              @click="filter = 'all'"
              :class="{ tag: true, 'active-item': filter == 'all' }"
            >
              <i class="fas fa-desktop ml-3 mr-2"></i>
              <small>{{ $t("reports.all") }}</small>
              <span>{{ screens.length }}</span>
            </div>
            <div
              @click="filter = 'online'"
              :class="{ tag: true, 'active-item': filter == 'online' }"
              style="color: green"
            >
              <i class="fas fa-signal ml-3 mr-2" style="color: green"></i
              ><small>{{ $t("reports.online") }}</small>
              <span>{{ onlineScreens }}</span>
            </div>
            <div
              @click="filter = 'offline'"
              :class="{ tag: true, 'active-item': filter == 'offline' }"
              style="color: red"
            >
              <i class="fas fa-signal ml-3 mr-2" style="color: red"></i
              ><small>{{ $t("reports.offline") }}</small>
              <span>{{ offlineScreens }}</span>
            </div>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" sm="12" md="12">
            <search :loading="loading" />
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <files-table
              :items="getScreens"
              :loading="loading"
              :showEdit="true"
              :headers="headers"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import filesTable from "@/components/files-table";
import { db, fb } from "@/firebase";
import { mapState, mapActions } from "vuex";

export default {
  name: "screens-report",
  components: {
    filesTable,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      screens: [],
      filter: "all",
      headers: [
        {
          text: this.$tc("headers.state"),
          value: "is_online",
        },
        {
          text: this.$tc("headers.device_name"),
          value: "device_name",
        },
        {
          text: this.$tc("headers.ip_address"),
          value: "ip_address",
        },

        {
          text: this.$tc("headers.mac_address"),
          value: "mac_address",
        },
        {
          text: this.$tc("headers.last_connectivity"),
          value: "last_connectivity_event",
        },
      ],
    };
  },
  computed: {
    onlineScreens() {
      return this.screens.filter((item) => item.is_online).length;
    },

    offlineScreens() {
      return this.screens.filter((item) => !item.is_online).length;
    },
    getScreens() {
      switch (this.filter) {
        case "all":
          return this.screens;
          break;

        case "online":
          return this.screens.filter((item) => item.is_online);
          break;

        case "offline":
          return this.screens.filter((item) => item.is_online == false);
          break;

        default:
          break;
      }
    },
  },
  beforeDestroy() {
    this.cleanSearch("");
  },

  methods: {
    ...mapActions(["cleanSearch"]),
  },
  mounted() {
    var httpDevicesReport = fb.functions().httpsCallable("httpDevicesReport");
    httpDevicesReport()
      .then((result) => {
        this.screens = result.data;
        this.screens.forEach((screen) => {
          screen.mac_address = screen.mac_address.split(" ")[0];
        });
        this.loading = false;
      })
      .catch((err) => {
        this.snackbarText = this.$tc("messages.unknown");
        this.snackbar = true;
        this.loading = false;
      });
  },
};
</script>

<style scoped lang="scss">
@import "../responsive";

.tag {
  font-size: 20px;
  cursor: pointer;
  border: 1px solid rgba(161, 161, 161, 0.616);
  padding: 15px;
  border-radius: 10px;
  margin-right: 15px;

  @include responsive(mobile) {
    margin-top: 5px;
    margin-right: 0;
  }
}

.active-item {
  border-bottom: 5px solid #1a5a76;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tags {
  display: flex;
  flex-direction: row;
  margin-left: 10px;

  @include responsive(mobile) {
    flex-direction: column;
    margin: 0;
    position: relative;
  }

  span {
    @include responsive(mobile) {
      position: absolute;
      right: 10%;
    }
  }

  /* margin-top: 5px; */
}
</style>