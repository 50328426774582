<template>
  <v-card :loading="loading" class="d-flex flex-column">
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card-title class="grid-close">
      <span class="headline"
        >{{ originalScreen ? $tc("dialog.edit") : $tc("dialog.add") }}
        {{ $tc("screens.title", 1) }}</span
      >
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container class="pa-5">
        <v-row>
          <v-col v-if="!originalScreen" cols="12" sm="12" md="12">
            <v-text-field
              v-model="code"
              :label="$tc('screens.code')"
              rounded
              :loading="loading"
              ref="code"
              filled
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="screen.name"
              :label="$tc('dialog.name')"
              rounded
              :loading="loading"
              filled
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="mb-0 pb-0">
            <v-textarea
              v-model="screen.description"
              :label="$tc('dialog.description')"
              rows="4"
              :loading="loading"
              rounded
              filled
              clearable
            ></v-textarea>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  <b> Configuraciones avanzadas</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch
                        v-model="screen.rotate"
                        label="Rotar pantalla"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-select
                        rounded
                        filled
                        :disabled="!screen.rotate"
                        label="Angulo de rotación"
                        hint="En sentido de las manecillas del reloj"
                        v-model="screen.rotateDegrees"
                        :items="rotation"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        :items="aspect"
                        item-text="name"
                        rounded
                        filled
                        item-value="value"
                        v-model="screen.aspect"
                        :label="$t('zones.adjust_multimedia_content')"
                      >
                        <template v-slot:selection="data">
                          <v-list-item-title>{{
                            data.item.name
                          }}</v-list-item-title>
                        </template>
                        <template v-slot:item="data">
                          <template>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                data.item.name
                              }}</v-list-item-title>
                              <small>{{ data.item.description }}</small>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-row justify="end">
              <v-btn
                :loading="loading"
                @click="originalScreen ? update() : save()"
                color="buttons"
                >{{ $tc("dialog.save") }}</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "add-screen",
  props: ["originalScreen"],
  data() {
    return {
      snackbarText: "",
      code: "",
      aspect: [
        {
          name: this.$t("zones.contain"),
          value: "contain",
          description: this.$t("zones.contain_description"),
        },
        {
          name: this.$t("zones.fill"),
          value: "fill",
          description: this.$t("zones.fill_description"),
        },
        {
          name: this.$t("zones.cover"),
          value: "cover",
          description: this.$t("zones.cover_description"),
        },
      ],
      rotation: [
        {
          text: "90 grados",
          value: 90,
        },
        {
          text: "180 grados",
          value: 180,
        },

        {
          text: "270 grados",
          value: 270,
        },
      ],
      screen: {
        name: "",
        description: "",
      },
      snackbar: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    handleSnackbar(msg) {
      this.snackbarText = msg;
      this.snackbar = true;
      this.loading = false;
    },
    update() {
      if (this.screen.name) {
        this.loading = true;
        db.collection("screens")
          .doc(this.originalScreen[".key"])
          .update({
            name: this.screen.name,
            description: this.screen.description ? this.screen.description : "",
            modifiedAt: new Date(),
            rotate: this.screen.rotate ? this.screen.rotate : false,
            aspect: this.screen.aspect ? this.screen.aspect : "",
            rotateDegrees: this.screen.rotateDegrees
              ? this.screen.rotateDegrees
              : null,
            modifiedBy: this.user.uid,
          })
          .then((response) => {
            this.loading = false;
            this.$emit(
              "success",
              this.$tc("messages.screen_edited_successfully")
            );
          })
          .catch((err) => {
            this.handleSnackbar(this.$tc("messages.unknown"));
          });
      } else {
        this.handleSnackbar(this.$tc("messages.insert_screen_name"));
      }
    },
    async save() {
      if (this.code && this.screen.name) {
        this.loading = true;

        let screensResult = await db
          .collection("tempScreens")
          .where("code", "==", parseInt(this.code))
          .get();

        if (!screensResult.size) {
          this.handleSnackbar(
            `${this.$tc("messages.screen_code_not_found", this.code, {
              code: this.code,
            })}`
          );
          return;
        } else if (!screensResult.docs[0].data().uuid) {
          this.handleSnackbar(this.$tc("messages.empty_screen"));
          return;
        } else {
          db.collection("screens")
            .add({
              name: this.screen.name,
              description: this.screen.description
                ? this.screen.description
                : "",
              createdAt: new Date(),
              createdBy: this.user.uid,
              active: true,
              uuid: screensResult.docs[0].data().uuid,
              groupId: this.groupId,
            })
            .then(async (response) => {
              this.loading = false;
              await db
                .collection("tempScreens")
                .doc(screensResult.docs[0].id)
                .update({
                  configured: true,
                });
              this.$emit("success", this.$tc("messages.screen_added"));
            })
            .catch((err) => {
              this.handleSnackbar(this.$tc("messages.unknown"));
            });
        }
      } else {
        this.handleSnackbar(this.$tc("messages.insert_all_fields"));
      }
    },
  },
  mounted() {
    if (this.originalScreen)
      this.screen = JSON.parse(JSON.stringify(this.originalScreen));
  },
};
</script>